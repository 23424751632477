<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      View Customer Address
    </span>
    <vs-tabs>
      <vs-tab label="Address">
        <div class="tab-text">
          <div class="vx-row mb-6">
            <div class="vx-col w-1/2">
              <vs-input v-if="data.customerCodeExternal == ''" class="w-full readonly" label="Code Customer" v-model="data.customerCode" name="Code" readonly/>
              <vs-input v-else class="w-full readonly" label="Customer Code" v-model="data.customerCode" name="Code" readonly/>
            </div>
            <div class="vx-col w-1/2">
              <vs-input class="w-full readonly" label="Customer Name" v-model="data.customerName" name="Name" readonly/>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-1/2">
              <vs-input class="w-full readonly" label="Code" v-model="data.code" name="Code" readonly/>
            </div>
            <div class="vx-col w-1/2">
              <vs-input class="w-full readonly" label="Code NOO" v-model="data.nooCode" name="Name" readonly/>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label">Status</label>
              <vs-checkbox v-model="data.status" vs-value="Billing Address" disabled
                >Billing Address</vs-checkbox
              >
              <vs-checkbox v-model="data.status" vs-value="Shipping Address" disabled
                >Shipping Address</vs-checkbox
              >
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full readonly"
                label="Territory*"
                v-model="data.territory"
                readonly
              />
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full readonly"
                label="Sales*"
                v-model="data.sales"
                readonly
              />
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full readonly"
                label="Country*"
                v-model="data.address.countryName"
                readonly
              />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full readonly"
                label="Province*"
                v-model="data.address.provinceName"
                readonly
              />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full readonly"
                label="City*"
                v-model="data.address.cityName"
                readonly
              />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full readonly"
                label="District*"
                v-model="data.address.districtName"
                readonly
              />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full readonly"
                label="Subdistrict*"
                v-model="data.address.subdistrictName"
                readonly
              />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full readonly"
                label="Address*"
                v-model="data.address.address"
                readonly
              />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full readonly"
                label="Postal Code*"
                v-model="data.address.postalCode"
                readonly
              />
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full readonly"
                label="RT / RW (Format 000/000)"
                v-model="data.rtRw"
                @keypress="isNumber($event)"
                name="RT/RW"
                readonly
              />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full readonly"
                label="Street No"
                v-model="data.streetNo"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Tax Collector</label>
            <vs-switch
              color="dark"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="data.isTaxCollector"
              disabled
            >
              <span slot="on">YES</span>
              <span slot="off">NO</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Tax Exempt</label>
            <vs-switch
              color="dark"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="data.isTaxExempt"
              disabled
            >
              <span slot="on">YES</span>
              <span slot="off">NO</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Tax Type</label>
            <div class="vx-row">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  v-model="taxType.selected"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Satus Block</label>
            <div class="vx-row">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full readonly"
                  v-model="StatusBlock.code"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
        <vs-divider style="width: 100%; margin-left: 0%">History Notes</vs-divider>
        <vs-table
          stripe
          border
          description
          :sst="true"
          :data="data.historyNotes"
        >
          <template slot="thead">
            <vs-th>Time</vs-th>
            <vs-th>User</vs-th>
            <vs-th>Notes</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="whitespace-no-wrap">{{ formatDateTime(tr.CreatedAt) }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ tr.personal_name }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ tr.notes }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-tab>
      <vs-tab label="Map">
        <div class="tab-text">
          <map-component :data="data.map" @data="setDataMap"></map-component>
        </div>
      </vs-tab>
      <vs-tab label="Contact">
        <div class="tab-text">
          <contact-component
            :data="data.contact"
            @data="setDataContact"
          ></contact-component>
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>
<script>
import ContactComponent from "../../../customer-address/form-component/Contact";
import MapComponent from "../../form-component/Map.vue";
import AddressComponent from "@/components/master/Address";
import moment from "moment";

export default {
  components: {
    ContactComponent,
    MapComponent,
    AddressComponent,
  },
  props: {
    customerId: {
      type: Number,
    },
    customerAddressId: {
      type: Number,
    },
    statusApproval: {
      type: String,
    },
    showButtonSubmit: {
      type: Boolean,
    },
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/oms/v1/sfa-master/customer-address",
        data: {
          historyNotes: [],
          notes: "",
          rtRw: "",
          street: "",
          streetNo: "",
          customerId: null,
          status: ["Shipping Address"],
          code: "",
          customerName: "",
          isTaxCollector: true,
          isTaxExempt: true,
          address: {
            countryName: "",
            countryID: 0,
            provinceName: "",
            provinceID: 0,
            cityName: "",
            cityID: 0,
            districtName: "",
            districtID: 0,
            subdistrictName: "",
            subdistrictID: 0,
            address: "",
            postalCode: "",
          },
          map: {
            latitude: "",
            longitude: "",
          },
          contact: {
            name: "",
            mobile: "",
            phone: "",
            email: "",
            fax: "",
            showWarningMobile: false,
            showWarningPhone: false,
            status_disabled: true,
          },
          customer: {
            options: [],
            selected: null,
          },
        },
        taxType: {
          selected: null,
          options: [],
        },
        territory: {
          options: [],
          selected: null,
        },
        salesPersonal: {
          selected: null,
          options: [],
        },
        StatusBlock: null,
        StatusBlockOption: [
          { id: 0, code: "0 - Un-Block" },
          { id: 1, code: "1 - Order Block" },
          { id: 2, code: "2 - Return Block" },
          { id: 3, code: "3 - All Block" }
        ],
      };
    },
    formatDateTime(s){
      const m = moment(new Date(s));
      return m.format('YYYY-MM-DD HH:mm:ss')
    },
    notify(color, title, message){
      this.$vs.notify({
        color: color,
        title: title,
        text: message,
        position: "top-right",
        iconPack: "feather",
        icon: "icon-x-circle",
      });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.customerAddressId) {
        this.$http
          .get("/api/oms/v1/sfa-master/customer-address/" + this.customerAddressId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.data.customerCode = resp.data.customer.code;
              this.data.customerCodeExternal = resp.data.customer.code_external;
              this.data.customerName = resp.data.customer.name;
              this.data.customerId = resp.data.customer_id;
              this.data.code = resp.data.code;
              this.data.nooCode = resp.data.code_noo;
              this.data.territory = resp.data.territory.code + " " + resp.data.territory.name;
              this.data.address.address = resp.data.address;
              this.data.address.postalCode = resp.data.postal_code;
              this.data.address.countryName = resp.data.country;
              this.data.address.provinceName = resp.data.province;
              this.data.address.cityName = resp.data.city;
              this.data.address.districtName = resp.data.district;
              this.data.address.subdistrictName = resp.data.sub_district;
              this.salesPersonal.selected = resp.data.sales;
              this.data.rtRw = resp.data.rt_rw;
              this.data.street = resp.data.street;
              this.data.streetNo = resp.data.street_no;
              if (resp.data.is_tax_collector == "1") {
                this.data.isTaxCollector = true;
              } else {
                this.data.isTaxCollector = false;
              }
              if (resp.data.is_tax_exempt == "1") {
                this.data.isTaxExempt = true;
              } else {
                this.data.isTaxExempt = false;
              }

              if (resp.data.status) {
                this.data.status = JSON.parse(resp.data.status);
              }

              this.data.map.address = resp.data.map_address;
              this.data.map.latitude = resp.data.latitude;
              this.data.map.longitude = resp.data.longitude;

              this.data.contact.name = resp.data.contact_name;
              this.data.contact.mobile = resp.data.contact_mobile;
              this.data.contact.phone = resp.data.phone;
              this.data.contact.email = resp.data.email;
              this.data.contact.fax = resp.data.fax;

              if (resp.data.sales_personal_id) {
                this.salesPersonal.selected = {id: resp.data.sales_personal_id}
                this.getDataSales(resp.data.sales_personal_id)
              }
              if (resp.data.tax_type_id) {
                this.setTaxTypeSelected(resp.data.tax_type_id)
              }
              this.data.historyNotes = resp.data.history_note
              this.StatusBlock = this.StatusBlockOption.find(
                option => option.id === resp.data.shiping_block
              );
            } else {
              this.notify("danger", "Error", resp.message)
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      } else {
        if (this.customerId) {
          this.$http
            .get("/api/oms/v1/sfa-master/customer/" + this.customerId)
            .then((resp) => {
              this.$vs.loading.close();
              if (resp.code == 200) {
                this.data.address.address = resp.data.address;
                this.data.address.postalCode = resp.data.postal_code;
                this.data.address.countryName = resp.data.country;
                this.data.address.provinceName = resp.data.province;
                this.data.address.cityName = resp.data.city;
                this.data.address.districtName = resp.data.district;
                this.data.address.subdistrictName = resp.data.sub_district;
              } else {
                this.notify("danger", "Error", resp.message)
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      }
    },
    getDataSales(personalID) { // 
      this.$vs.loading();
      let url = "/api/oms/v1/sfa-master/personal/"
      this.$http
        .get(url + personalID)
        .then((resp) => {
          if (resp.status == "success") {
            this.data.sales = resp.data.CodeExternal + " " + resp.data.Name
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setDataContact(val) {
      this.data.contact = val;
    },
    setDataMap(val) {
      this.data.map = val;
    },
    setTaxTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/oms/v1/sfa-master/tax-type/" + id).then((resp) => {
        if (resp.status == "success") {
          this.taxType.selected = resp.data.code + " " + resp.data.name;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
  mounted() {
    this.getData();

    if (this.customerId == 0 | isNaN(this.customerId)) {
      console.log("get customer from mounted")
      this.getDataCustomer()
    }
  },
  computed: {},
  watch: {
    customerAddressId(v) {
      if (v) {
        this.getData();
      } else {
        this.getDataCustomer()
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
<style >
.readonly > div > input {
  background-color: #ebebeb;
}
</style>
