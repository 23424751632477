<template>
  <div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input :class="class_name" label="Name" v-model="mutableData.name" :readonly="mutableData.status_disabled" />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          :class="class_name"
          label="Phone"
          v-model="mutableData.phone"
          v-validate="'required|numeric|max:13|min:10'"
          name="Phone"
          :readonly="mutableData.status_disabled"
        />
        <span class="text-danger text-sm" v-show="errors.has('Phone')">{{
          errors.first("Phone")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          :class="class_name"
          label="Mobile"
          v-model="mutableData.mobile"
          v-validate="'numeric|max:13|min:10'"
          name="Mobile"
          :readonly="mutableData.status_disabled"
        />
        <span class="text-danger text-sm" v-show="errors.has('Mobile')">{{
          errors.first("Mobile")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          :class="class_name"
          label="Email"
          v-model="mutableData.email"
          v-validate="'email'"
          name="Email"
          :readonly="mutableData.status_disabled"
        />
        <span class="text-danger text-sm" v-show="errors.has('Email')">{{ errors.first('Email') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          :class="class_name"
          label="Fax"
          v-model="mutableData.fax"
          v-validate="'numeric'"
          name="Fax"
          :readonly="mutableData.status_disabled"
        />
        <span class="text-danger text-sm" v-show="errors.has('Fax')">{{ errors.first('Fax') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      class_name: "",
      mutableData: {
        name: "",
        mobile: "",
        phone: "",
        email: "",
        fax: "",
        status_disabled: false,
      }
    };
  },
  mothods: {},
  mounted() {
    this.mutableData.name = this.data.name;
    this.mutableData.mobile = this.data.mobile;
    this.mutableData.phone = this.data.phone;
    this.mutableData.email = this.data.email;
    this.mutableData.fax = this.data.fax;
    this.mutableData.status_disabled = this.data.status_disabled;

    if (this.data.status_disabled) {
      this.class_name = "w-full readonly"
    } else {
      this.class_name = "w-full"
    }
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        console.log("data", this.data)
        if(this.data.showWarningPhone){
          this.errors.add({
            field: "Phone",
            msg: "The Phone field is required",
          });
        }
        if(this.data.showWarningMobile){
          this.errors.add({
            field: "Mobile",
            msg: "The Mobile field is required",
          });
        }
      },
    },
    mutableData: {
      deep: true,
      handler(val) {
        this.$emit("data", val);
      }
    }
  }
};
</script>